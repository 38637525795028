

.h1-pixel {
  font-size: 4.4rem !important;
  font-family: $font-family-pixel;
  padding-left: 6px;
  margin: 0 0 1vh 0;
  line-height: 12vh;
  font-weight: 400;      
}

.landing{
  position: relative;
  height: 100vh;  
  @supports (height: 100dvh){
    height: 100dvh;
  }

  canvas{
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    @supports (height: 100dvh){
      width: 100dvw;
      height: 100dvh;
    }
  }

  .landing-container{
    z-index: 1;
    height: 83vh;
    @supports (height: 83dvh){
      height: 83dvh;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    
    h1{
      font-size: 9vh !important;
      line-height: 11vh;
      margin: 0 0 0.5vh 0;

      @supports (font-size: 9dvh){
        font-size: 9dvh;
        line-height: 11dvh;
        margin: 0 0 0.5dvh 0;
      }
      font-family: $font-family-pixel;
      padding-left: 6px;
      font-weight: 400;      
    }

    .img-fox{
      margin: 6vh 0;
      height: 35vh;
      width: 35vw;
      @supports (height: 35dvh){
        margin: 6dvh 0;
        height: 35dvh;
        width: 35dvw;  
      }
    }

    .btn{
      margin: 5vh 0 2.5vh;
      @supports (margin: 1dvh){
        margin: 5dvh 0 2.5dvh;
      }
      width: 100%;
    }
  }
}