.animated-dot{
    z-index: 100;
    position: absolute;
    border-radius: 50%;
    transform-origin: 50% 50%;
    background-color: var(--theme-gold-light);
    opacity: 0;
}


.animated-congrats{
    z-index: 100;
    position: absolute;
    color: var(--theme-default);
    opacity: 0;
    font-size: 6vh;
    font-weight: 700;
    transform: translate(-50%, -150%);
    animation: animated-congrats-dissapear 3s ease-out;
}

@keyframes animated-congrats-dissapear {
  from {
    opacity: 0.5;
    transform: translate(-50%, -150%);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -350%);
  }
}



.animated-hand-point{
  z-index: 100;
  left: 25%;
  position: absolute;
  color: var(--theme-default);
  opacity: 0;
  transform: translateX(-15%);
  animation: animated-hand-point 1s ease-in-out 1s infinite;
}

@keyframes animated-hand-point {
  0% {
    opacity: 0.8;
    top: 100%;
  }
  20% {
    opacity: 0.8;
    top: 0%;
  }
  30% {
    opacity: 0.8;
    top: 0%;
  }
  100% {
    opacity: 0.8;
    top: 100%;
  }
}

.animated-hand-area{
  z-index: 100;
  left: 0%;
  top: 0%;
  opacity: 0; 
  position: absolute;
  color: var(--theme-default);
  transform: translateX(-48%);
  animation: animated-hand-area 3s linear 3.5s infinite; //
}

@keyframes animated-hand-area {
  0% {
    top: 0%;
    left: 0%;
    opacity: 0.8; 
  }
  20% {
    top: 0%;
    left: 100%;
    opacity: 0.8; 
  }

  30% {
    top: 0%;
    left: 100%;
    opacity: 0; 
  }

  40% {
    top: 0%;
    left: 50%;
    opacity: 0; 
  }

  50% {
    top: 0%;
    left: 50%;
    opacity: 0.8; 
  }
  80% {
    top: 120%;
    left: 50%;
    opacity: 0.8; 
  }
  90% {
    top: 120%;
    left: 50%;
    opacity: 0; 
  }
  100% {
    top: 0%;
    left: 0%;
    opacity: 0; 
  }
}



.animated-hand-horizontal{
  z-index: 100;
  position: absolute;
  color: var(--theme-default);
  opacity: 0;
  transform: translateX(-48%);
  animation: animated-hand-horizontal 2s linear 1.5s infinite; //
}

@keyframes animated-hand-horizontal {
  0% {
    opacity: 0;
    left: 0%;
  }
  10% {
    opacity: 0.8;
    left: 0%;
  }
  40% {
    opacity: 0.8;
    left: 100%;
  }
  50% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 0;
    left: 0%;
  }
}

.animated-hand-selection-horizontal{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border: 2px solid var(--theme-default);
  opacity: 0;
  animation: animated-hand-selection-horizontal 2s linear 1.6s infinite;
}

@keyframes animated-hand-selection-horizontal {
  0% {
    opacity: 0;
    width: 20%;
  }
  10% {
    opacity: 1;
    width: 20%;
  }
  16% {
    width: 40%;
  }
  22% {
    width: 60%;
  }
  28% {
    width: 80%;
  }
  34% {
    opacity: 1;
    width: 100%;
  }
  80% {
    opacity: 0;
    width: 100%;
  }
  100% {
    opacity: 0;
    width: 20%;
  }
}



.animated-hand-selection-area{
  position: absolute;
  height: 25%;
  width: 100%;
  top: 0;
  left: 0;
  border: 2px solid var(--theme-default);
  opacity: 0;
  animation: animated-hand-selection-area 3s linear 3.6s infinite;
}

@keyframes animated-hand-selection-area {
  0% {
    left: 0;
    opacity: 0;
    width: 33.33%;
    height: 25%;
  }
  5% {
    left: 0;
    opacity: 1;
    width: 33.33%;
    height: 25%;
  }
  16% {
    left: 0;
    opacity: 1;
    width: 100%;
    height: 25%;
  }
  30% {
    left: 0;
    opacity: 0;
    width: 100%;
    height: 25%;
  }
  40% {
    left: 33.33%;
    opacity: 0;
    width: 33.33%;
    height: 25%;
  }
  50% {
    left: 33.33%;
    opacity: 1;
    width: 33.33%;
    height: 25%;
  }
  74% {
    left: 33.33%;
    opacity: 1;
    width: 33.33%;
    height: 100%;
  }
  90% {
    left: 33.33%;
    opacity: 0;
    width: 33.33%;
    height: 100%;
  }

  100% {
    left: 0;
    opacity: 0;
    width: 33.33%;
    height: 25%;
  }

}


/*
@keyframes animated-hand-area {
  0% {
    top: 0%;
    left: 0%;
    opacity: 0.8; 
  }
  20% {
    top: 0%;
    left: 100%;
    opacity: 0.8; 
  }

  30% {
    top: 0%;
    left: 100%;
    opacity: 0; 
  }

  40% {
    top: 0%;
    left: 50%;
    opacity: 0; 
  }

  50% {
    top: 0%;
    left: 50%;
    opacity: 0.8; 
  }
  80% {
    top: 100%;
    left: 50%;
    opacity: 0.8; 
  }
  90% {
    top: 100%;
    left: 50%;
    opacity: 0; 
  }
  100% {
    top: 0%;
    left: 0%;
    opacity: 0; 
  }
}

*/