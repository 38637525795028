
svg.recharts-surface text {
	font-size: 0.765rem;
  font-weight: 500;
  stroke-width: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  font-stretch: normal;
  font-style: normal;  
  touch-action: none;
  user-select: none;  
}

svg.recharts-surface {
  touch-action: none;
  user-select: none;  
  transform: translate3d(0, 0, 0);
}

.recharts-scatter-line path{
  stroke-linejoin: round;
  border-radius: 15px;
  stroke-width: 2px;
}

.reference-line line{
  stroke: var(--theme-muted);
  stroke-width: 0.5px;
  stroke-opacity: 0.4;
}

.reference-dashed-line line{
  //stroke: var(--theme-muted);
  //stroke-width: 4px;
  //stroke-dasharray: 4, 2;
  stroke: var(--theme-default-accent);
  stroke-width: 0.5px;
  stroke-opacity: 0.9;
  stroke-dasharray: 1, 3;
  //animation: dashoffset-animation 60s linear 0s infinite;
}

@keyframes dashoffset-animation {
  from {stroke-dashoffset: 100%; }
  to {stroke-dashoffset: 0%;}
}


.animateTranformY100{
  opacity: 0;
  transform: translateY(100%); 
  animation: animateTranformY100 0.3s ease-out 0.5s;  
  animation-fill-mode: forwards;
}

@keyframes animateTranformY100 {
  from {
    opacity: 0;
    transform: translateY(100%); }
  to {
    opacity: 1;
    transform: translateY(0); }
}


/* @property --grid-box-num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@keyframes counter {
  from {
    --grid-box-num: 0;
  }
  to {
    --grid-box-num: 9;
  }
}
 */

.blur-filter{
  backdrop-filter: blur(3px);
  transform: translate3d(0, 0, 0);
}

.glow{
  text-shadow: white 0px 0px 6px;
}

.glow-small{
  text-shadow: white 0px 0px 20px;
}

.grid-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //font-size: 14px;
  font-size: 0.765rem;
  font-weight: 500;
  color: var(--theme-primary);
  border-style: solid;
  border-color: transparent;//var(--theme-muted-transparent);
  border-width: 0.5px; 
  //background-color: var(--theme-overlay-accent-transparent);
  cursor: pointer;
  user-select: none;
  touch-action: none;

/*   animation: counter 0.2s infinite linear;
  transition: --grid-box-num 0.2s;
  counter-reset: num var(--grid-box-num);

  span::after {
      content: counter(num);
      width: 20px;
      height: 20px;    
    }
 */
}

.grid-box.updated {
  color: var(--theme-primary-accent) !important;
  font-weight: 600;
}

.grid-box.disabled {
  color: var(--theme-muted) !important;
}

.grid-box.selected {
  border-style: none !important;

  &::after{
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border: 2px solid var(--theme-default);// !important;
    border-radius: 0px;
    z-index: 100;
  }
}

.grid-box.invalid {
  border-color: var(--theme-red) !important;
  &::after{
    border-color: var(--theme-red) !important;
  }
}



.svg-box {  
  g {    
    transition: all 0.15s ease-out 0.3s;
  }
}

.box{
  transition: transform 0.15s linear, opacity 0.5s linear 0.15s;
}
.box.shifted{
  transform: translate3d(var(--price-offset), var(--time-offset), 0);
  transition: opacity 0.5s linear 0.15s;
}
.box.new{
  transform: translate3d(0, var(--box-appear-time-offset), 0);
}
.box.executed{
  opacity: 0;
}

.recharts-scatter.line{
  //transform: translate3d(0, 0, 0);
  transition: transform 0.15s linear;
}
.recharts-scatter.line.shifted{
  transform: translate3d(var(--price-offset), var(--time-offset), 0);
  transition: none;
}

.recharts-scatter.dots{
  //transform: translate3d(0, 0, 0);
  transition: transform 0.15s linear;
}
.recharts-scatter.dots.shifted{
  transform: translate3d(var(--price-offset), var(--time-offset), 0);
  transition: none;
}

.dotArea{
  circle{
    stroke-opacity: 0;    
    fill-opacity: 0; 
    //transition: r 0.5s cubic-bezier(0, 1.37, 0.31, 1.37) 0.3s, stroke-width 0.5s cubic-bezier(0, 1.37, 0.31, 1.37) 0.3s,
    //fill-opacity 1s ease-in 0.3s, stroke-opacity 1s ease-in 0.3s;
    transition: all 0.2s cubic-bezier(0, 1.37, 0.31, 1.37) 0.2s;
  } 
}

.dotArea.standBy {
  circle{
    animation: standByAnimation 0.5s ease-in-out 0s infinite alternate;
  } 
}

@keyframes standByAnimation {
  from {
    stroke-opacity: 0.02;    
  }
  to {    
    stroke-opacity: 0.06;    
  }
}

.dotArea.getCloser {
  circle{
    stroke-opacity: 0.02;    
    fill-opacity: 0.02; 
  } 
}

.label{
  fill: var(--theme-default);
  color: var(--theme-default);
  transition: fill 2s ease-in-out, color 2s ease-in-out;
}

.label.muted{
  fill: var(--theme-muted) !important;
  color: var(--theme-muted) !important;
  transition: fill 2s ease-in-out, color 2s ease-in-out;
}

/* 
@keyframes flashing-animation {
  from {fill: var(--theme-default); }
  to {fill: var(--theme-muted);}
}

.label.flashing{
  transition: fill 2s ease-in-out;
  fill: var(--theme-default);
  animation: flashing-animation 1s ease-in-out 0s infinite alternate;
}

 */


 .btn-withdraw-dropdown{
    display: inline-block;
    width: 12px;
  
    &:before {
      position: absolute;
      height: 17px;
      width: 17px;
      background-color: var(--theme-background);
      content: "";
      border-radius: 50%;
      margin: -13px 0 0 -5px;
      z-index: 1;
    }
      
     &:after {
      position: absolute;
      height: 11px;
      width: 21px;
      background-color: var(--theme-muted);
      content: "";
      mask-image: url(../../public/img/drropdown.svg);
      margin: -9px 0 0 -7px;
      transform: scale(0.5, 0.5);
      z-index: 2;
    }
  }
  
.btn-withdraw-dropdown-circle{
  display: inline-block;
  width: 20px;

  &:before {
    position: absolute;
    height: 17px;
    width: 17px;
    background-color: var(--theme-muted);
    content: "";
    border-radius: 50%;
    margin: -13px 0 0 -5px;
    z-index: 1;
  }
    
   &:after {
    position: absolute;
    height: 11px;
    width: 21px;
    background-color: var(--theme-background);
    content: "";
    mask-image: url(../../public/img/drropdown.svg);
    margin: -9px 0 0 -7px;
    transform: scale(0.5, 0.5);
    z-index: 2;
  }
}

.btn-withdraw-arrowdown{
  display: inline-block;
  width: 20px;

  &:before {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: var(--theme-muted);
    content: "";
    border-radius: 50%;
    margin: -13px 0 0 -5px;
    z-index: 1;
  }
    
  &:after {
    position: absolute;
    height: 14px;
    width: 8px;
    background-color: var(--theme-background);
    content: "";
    mask-image: url(../../public/img/arrowdown.svg);
    margin: -11px 0 0 0px;
    transform: scale(1, 0.8) rotate(180deg);
    z-index: 2;
  }
}

.btn-withdraw-circle{
  display: inline-block;
  width: 20px;

  &:before {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: var(--theme-muted);
    content: "";
    border-radius: 50%;
    margin: -13px 0 0 -5px;
    z-index: 1;
  }
    
  &:after {
    position: absolute;
    height: 8px;
    width:8px;
    background-color: var(--theme-background);
    content: "";
    mask-image: url(../../public/img/circle.svg);
    margin: -8px 0 0 0;
    transform: scale(1.8, 1.8);
    z-index: 2;
  }
}


.btn-withdraw-coin{
  display: inline-block;
  width: 20px;
   
  &:after {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: var(--theme-muted);
    content: "";
    mask-image: url(../../public/img/coin.svg);
    margin: -13px 0 0 -6px;    
  }
}


.sound-controller{
  border-radius: 20px;
  position: absolute;
  width: 65px;
  height: 34px;
  border-radius: 20px;
  z-index: 10;
    
  .btn-secondary{
    border-radius: 20px;
    border-width: 0;
    font-size: 26px;
    line-height: 34px;
    padding: 0;

    --bs-btn-color: var(--theme-default);
    --bs-btn-hover-color: var(--theme-default-accent);
    --bs-btn-active-color: var(--theme-default-accent);
    --bs-btn-disabled-color: var(--theme-muted);
  
    --bs-btn-bg: var(--theme-overlay-transparent);
    --bs-btn-hover-bg: var(--theme-overlay-accent);
    --bs-btn-active-bg: var(--theme-overlay-accent);
    --bs-btn-disabled-bg: var(--theme-overlay-transparent);
    @media (#{$max_phones--big}) {
      --bs-btn-hover-bg: var(--theme-overlay-transparent);
    }
  }
    
}

.bet-controller{
  position: absolute;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  z-index: 10;
    
  div{
    float: left;
    line-height: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    //background-color: var(--theme-overlay-transparent);
    //background-color: var(--theme-overlay-glass);    
  }

  .btn-secondary{
    border-width: 0;
    font-size: 26px;
    line-height: 38px;
    padding: 0;

    --bs-btn-color: var(--theme-default);
    --bs-btn-hover-color: var(--theme-default-accent);
    --bs-btn-active-color: var(--theme-default-accent);
    --bs-btn-disabled-color: var(--theme-muted);
  
    --bs-btn-bg: var(--theme-overlay-transparent);
    --bs-btn-hover-bg: var(--theme-overlay-accent);
    --bs-btn-active-bg: var(--theme-overlay-accent);
    --bs-btn-disabled-bg: var(--theme-overlay-transparent);
    @media (#{$max_phones--big}) {
      --bs-btn-hover-bg: var(--theme-overlay-transparent);
    }
  
  }

  .btn-left  {
  border-radius: 50% 0 0 50%;
  }

  .btn-right{
  border-radius: 0 50% 50% 0;
  };
}


body {
	background: #454a59;
}

.pulsating-circle {
  position: relative;
  left: -10px;
  top: 0px;
  width: 11px;
  height: 11px;
  
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: var(--theme-default);
    animation: pulse-ring 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    top: -11px;
    opacity: 0.5;
  }
  
  &:after {
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--theme-primary-accent);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.001s infinite;
  }
}

@keyframes pulse-ring {
  0% {
      transform: scale(0);
      opacity: 0;
  }
  40% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
      transform: scale(0.2);
      opacity: 0.2;
  }
  100% {
      opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.6);
  }
  15% {
    transform: scale(.6);
  }
  50% {
    transform: scale(0.8);
  }
  85% {
    transform: scale(.6);
  }
  100% {
    transform: scale(.6);
  }
}