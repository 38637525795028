#site {

 
  html, body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;    
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 500;
    color: #212b34;
  }

  h1 {
    font-size: 70px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
    color: #878787;
    line-height: 2;
  }

  img {
    max-width: 100%;
  }

  input:focus,
  select:focus,
  button:focus,
  textarea:focus {
    outline: none;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    outline: none;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
  }

  /*---------------------
    Helper CSS
  -----------------------*/

  .section-title {
    text-align: center;
    margin-bottom: 75px;
  }

  .section-title h2 {
    padding-top: 10px;
    font-size: 36px;
  }

  .set-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  .spad {
    padding-top: 97px;
    padding-bottom: 97px;
  }

  .text-white h1,
  .text-white h2,
  .text-white h3,
  .text-white h4,
  .text-white h5,
  .text-white h6,
  .text-white p,
  .text-white span,
  .text-white li,
  .text-white a {
    color: #fff;
  }

  .rating i {
    color: #fbb710;
  }

  .rating .is-fade {
    color: #e0e3e4;
  }


  /* buttons */
  .site-btn {
    display: inline-block;
    text-align: center;
    font-size: 15px;
    color: #212b34;
    padding: 16px 30px;
    min-width: 153px;
    border-radius: 10px;
    font-weight: 500;
    border: none;
    background: #68cd86;
    cursor: pointer;
  }

  .site-btn.btn-sm {
    padding: 10px 30px;
    min-width: 163px;
  }

  .site-btn.btn-secondary {
    background: #fff;
    backdrop-filter: blur(3px);
    transform: translate3d(0, 0, 0);

  }

  .site-btn:hover {
    color: #212b34;
  }

  .cata {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    padding: 6px 25px;
  }

  .cata.new {
    background: #ff205f;
  }

  .cata.strategy {
    background: #4eae60;
  }

  .cata.racing {
    background: #694eae;
  }

  .cata.adventure {
    background: #40abf5;
  }


  .header-section {
    background: #212b34;
    clear: both;
    overflow: hidden;
    padding: 18px 0;
    border-bottom: 1px solid #68cd86;
  }

  .site-logo {
    display: inline-block;
    float: left;
    padding-top: 6px;
  }

  .user-panel {
    float: right;
    font-weight: 500;
    background: #68cd86;
    padding: 8px 28px;
    border-radius: 30px;
  }

  .user-panel a {
    font-size: 14px;
    color: #212b34;
  }

  .main-menu {
    float: right;
    margin-right: 170px;
  }

  .main-menu ul {
    list-style: none;
  }

  .main-menu ul li {
    display: inline;
  }

  .main-menu ul li a {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    margin-left: 35px;
    font-weight: 500;
    padding: 10px 5px;
  }

  .main-menu ul li a:hover {
    color: #68cd86;
  }

  .header-btn {
    float: right;
    margin-right: 0;
  }

  .nav-switch {
    display: none;
  }

  /*------------------
    Hero Section
  ---------------------*/

  .hero-slider .hs-item {
    height: 865px;
    display: table;
    width: 100%;
  }

  .hero-slider .hs-text {
    display: table-cell;
    vertical-align: middle;
  }

  .hero-slider .hs-text h2 {
    color: #fff;
    font-weight: 400;
    font-size: 60px;
    margin-bottom: 30px;
    position: relative;
    top: -80px;
    opacity: 1;
  }

  .hero-slider .hs-text h2 span {
    color: #68cd86;
  }

  .hero-slider .hs-text p {
    color: #fff;
    margin-bottom: 30px;
    position: relative;
    position: relative;
    top: -90px;
    opacity: 1;
  }

  .hero-slider .hs-text .site-btn {
    position: relative;
    top: 0px;
    opacity: 1;
  }

  .hero-slider .owl-item.active .hs-item h2,
  .hero-slider .owl-item.active .hs-item p,
  .hero-slider .owl-item.active .hs-item .site-btn {
    top: 0;
    opacity: 1;
  }

  .hero-slider .owl-item.active .hs-item h2 {
    -webkit-transition: all 0.5s ease 1s;
    -o-transition: all 0.5s ease 1s;
    transition: all 0.5s ease 1s;
  }

  .hero-slider .owl-item.active .hs-item p {
    -webkit-transition: all 0.5s ease 0.8s;
    -o-transition: all 0.5s ease 0.8s;
    transition: all 0.5s ease 0.8s;
  }

  .hero-slider .owl-item.active .hs-item .site-btn {
    -webkit-transition: all 0.5s ease 0.6s;
    -o-transition: all 0.5s ease 0.6s;
    transition: all 0.5s ease 0.6s;
  }

  .hero-slider .owl-dots {
    position: relative;
    max-width: 1146px;
    margin: -33px auto 0;
    top: -67px;
    z-index: 5;
  }

  .hero-slider .owl-dots .owl-dot {
    display: inline-block;
    margin-right: 13px;
    width: 33px;
    height: 33px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding-top: 7px;
    border-radius: 40px;
    background: #dbe2ec;
  }

  .hero-slider .owl-dots .owl-dot.active {
    background: #68cd86;
  }

  /*---------------------
    Latest News section
  -----------------------*/

  .latest-news-section {
    overflow: hidden;
    display: block;
    clear: both;
    background: #212b34;
    padding: 24px 0;
    position: relative;
  }

  .ln-title {
    position: absolute;
    height: 100%;
    width: 29%;
    left: 0;
    top: 0;
    text-align: right;
    padding-top: 24px;
    padding-right: 66px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background: #68cd86;
    z-index: 1;
  }

  .news-ticker {
    width: 71%;
    float: right;
    overflow: hidden;
    display: inline-block;
  }

  .news-ticker-contant .nt-item {
    margin-right: 35px;
    color: #fff;
    display: inline-block;
  }

  .news-ticker-contant .nt-item span {
    margin-right: 35px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    padding: 4px 20px;
  }

  .news-ticker-contant .nt-item span.new {
    background: #ff205f;
  }

  .news-ticker-contant .nt-item span.strategy {
    background: #4eae60;
  }

  .news-ticker-contant .nt-item span.racing {
    background: #694eae;
  }

  /*------------------
    Feature Section
  ---------------------*/

  .feature-item {
    height: 415px;
    position: relative;
  }

  .feature-item:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.65;
  }

  .feature-item .cata {
    position: relative;
    margin-top: 28px;
    margin-left: 29px;
    z-index: 3;
  }

  .feature-item .fi-content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 26px 24px;
    z-index: 2;
  }

  .feature-item .fi-content h5 {
    margin-bottom: 18px;
  }

  .feature-item .fi-content .fi-comment {
    font-size: 12px;
    opacity: 0.54;
  }

  /*----------------------
    Recent Game Section
  ------------------------*/

  .recent-game-section {
    background-color: #eef2f6;
    border-top: 1px solid #d6dee7;
    border-bottom: 1px solid #d6dee7;
  }

  .recent-game-item .rgi-thumb {
    height: 204px;
    padding: 25px 28px;
  }

  .recent-game-item .rgi-content {
    padding: 34px 22px 20px;
    background: #fff;
    border: 1px solid #d6dee7;
    border-top: none;
    position: relative;
  }

  .recent-game-item .rgi-content h5 {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .recent-game-item .rgi-content .comment {
    font-size: 12px;
    color: #737373;
  }

  .recent-game-item .rgi-extra {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 70px;
  }

  .recent-game-item .rgi-extra .rgi-star,
  .recent-game-item .rgi-extra .rgi-heart {
    height: 35px;
    width: 35px;
    float: left;
    display: block;
    padding: 6px 8px 0;
  }

  .recent-game-item .rgi-extra .rgi-star {
    background: #68cd86;
  }

  .recent-game-item .rgi-extra .rgi-heart {
    background: #ff205f;
  }

  .tournaments-section {
    background-image: url('pattern.png');
    background-repeat: repeat;
  }

  .tournament-title {
    display: inline-block;
    padding: 7px 25px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    background: #fb6e10;
  }

  .tournament-item {
    background: #252525;
  }

  .tournament-item .ti-notic {
    display: inline-block;
    padding: 8px 21px;
    font-size: 12px;
    font-weight: 700;
    color: #212b34;
    text-transform: uppercase;
    background: #68cd86;
  }

  .tournament-item .ti-content {
    padding: 38px 24px;
    overflow: hidden;
  }

  .tournament-item .ti-thumb {
    width: 168px;
    height: 178px;
    float: left;
  }

  .tournament-item .ti-text {
    padding-left: 195px;
  }

  .tournament-item .ti-text h4 {
    color: #68cd86;
    margin-bottom: 20px;
  }

  .tournament-item .ti-text ul {
    list-style: none;
    margin-bottom: 20px;
  }

  .tournament-item .ti-text ul li {
    color: #fff;
    font-size: 12px;
    margin-bottom: 6px;
  }

  .tournament-item .ti-text ul li span {
    color: #9a9a9a;
  }

  .tournament-item .ti-text p {
    font-size: 12px;
    margin-bottom: 0;
  }

  .tournament-item .ti-text p span {
    font-weight: 500;
    color: #68cd86;
  }

  /*----------------------
    Review Section
  ------------------------*/

  .review-section {
    background-position: right top;
  }

  .review-item .review-cover {
    position: relative;
    margin-bottom: 30px;
    height: 345px;
  }

  .review-item .review-cover .score {
    position: absolute;
    width: 54px;
    height: 54px;
    top: -27px;
    left: 26px;
    padding-top: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
  }

  .review-item .review-cover .score.yellow {
    background: #68cd86;
  }

  .review-item .review-cover .score.purple {
    background: #694eae;
  }

  .review-item .review-cover .score.pink {
    background: #ff20ae;
  }

  .review-item .review-cover .score.green {
    background: #4eae60;
  }

  .review-item .review-text {
    text-align: center;
  }

  .review-item .review-text h5 {
    margin-bottom: 20px;
  }

  .review-item .review-text p {
    margin-bottom: 0;
  }

  .review-item .rating {
    margin-bottom: 20px;
  }

  .review-item .rating i {
    font-size: 12px;
  }

  /*----------------------
    Footer top Section
  ------------------------*/

  .footer-top-section {
    background-image: url('pattern.png');
    background-repeat: repeat;
    padding: 72px 0;
  }

  .footer-top-section .container {
    position: relative;
  }

  .footer-top-bg {
    position: absolute;
    left: -30px;
    bottom: -85px;
    z-index: 2;
  }

  .footer-logo img {
    margin-bottom: 35px;
  }

  .footer-widget {
    padding: 40px 30px 7px;
    background: #252525;
    border: 1px solid #4a4a4a;
  }

  .footer-widget .fw-title {
    color: #fff;
    margin-bottom: 40px;
  }

  .latest-blog .lb-item {
    margin-bottom: 23px;
    overflow: hidden;
  }

  .latest-blog .lb-item .lb-thumb {
    width: 97px;
    height: 97px;
    float: left;
  }

  .latest-blog .lb-item .lb-content {
    padding-left: 110px;
  }

  .latest-blog .lb-item .lb-content .lb-date {
    font-size: 12px;
    color: #68cd86;
    margin-bottom: 8px;
  }

  .latest-blog .lb-item .lb-content p {
    font-size: 12px;
    margin-bottom: 5px;
    color: #d3d3d3;
  }

  .latest-blog .lb-item .lb-content .lb-author {
    font-size: 12px;
    color: #d3d3d3;
    opacity: 0.45;
  }

  .top-comment {
    overflow: hidden;
  }

  .top-comment .tc-item {
    margin-bottom: 23px;
    overflow: hidden;
  }

  .top-comment .tc-item .tc-thumb {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    float: left;
  }

  .top-comment .tc-item .tc-content {
    padding-left: 100px;
  }

  .top-comment .tc-item .tc-content p {
    font-size: 12px;
    color: #d3d3d3;
    margin-bottom: 5px;
  }

  .top-comment .tc-item .tc-content p a {
    color: #ff205f;
  }

  .top-comment .tc-item .tc-content p span {
    color: #7a7a7a;
  }

  .top-comment .tc-item .tc-content .tc-date {
    font-size: 12px;
    color: #68cd86;
  }

  /*----------------------
    Footer Section
  ------------------------*/

  .footer-section {
    padding: 18px 0 16px;
    overflow: hidden;
    background: #252525;
  }

  .footer-menu {
    list-style: none;
    float: right;
  }

  .footer-menu li {
    display: inline;
  }

  .footer-menu li a {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #aeaeae;
    margin-left: 30px;
  }

  .copyright {
    float: left;
    margin-bottom: 0;
    font-weight: 500;
    color: #aeaeae;
  }

  /*------------------
    Other Pages
  --------------------
  ====================*/

  .page-info-section {
    height: 499px;
    position: relative;
  }

  .page-info-section .pi-content {
    position: absolute;
    width: 100%;
    bottom: 65px;
    left: 0;
    z-index: 2;
  }

  .page-info-section .pi-content h2 {
    font-size: 60px;
    margin-bottom: 25px;
    font-weight: 400;
  }

  .site-pagination {
    padding-top: 50px;
  }

  .site-pagination a,
  .site-pagination span {
    width: 33px;
    height: 33px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 7px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    color: #212b34;
    margin-right: 10px;
  }

  .site-pagination .active {
    background: #68cd86;
  }

  .site-pagination.sp-style-2 a,
  .site-pagination.sp-style-2 span {
    background: #e5e5e5;
  }

  .site-pagination.sp-style-2 .active {
    background: #68cd86;
  }

  .widget-item {
    margin-bottom: 73px;
  }

  .widget-item:last-child {
    margin-bottom: 0;
  }

  .widget-item .widget-title {
    margin-bottom: 40px;
  }

  .widget-item .latest-blog .lb-item .lb-content p,
  .widget-item .top-comment .tc-item .tc-content p {
    color: #212b34;
  }

  .widget-item .latest-blog .lb-item .lb-content .lb-author {
    color: #737373;
  }

  .widget-item .top-comment .tc-item .tc-content {
    padding-right: 20px;
  }

  .widget-item .review-item {
    background: #eff2f5;
  }

  .widget-item .review-item .review-text {
    padding: 0 35px 20px;
  }

  .search-widget {
    position: relative;
  }

  .search-widget input {
    width: 100%;
    height: 41px;
    border: 1px solid #d6dee7;
    padding-left: 22px;
    padding-right: 45px;
    font-size: 12px;
  }

  .search-widget button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: none;
    border: none;
    padding-right: 15px;
    color: #878787;
    cursor: pointer;
    i {
      margin-top: 10px;
    }
  }

  /*-----------------
    Categories page
  --------------------*/

  .recent-game-page .recent-game-item {
    margin-bottom: 42px;
  }

  /*-----------------
    Single blog page
  --------------------*/

  .single-blog-page .blog-thumb {
    height: 424px;
    padding-top: 24px;
    padding-left: 29px;
    margin-bottom: 40px;
    position: relative;
  }

  .single-blog-page .blog-thumb .rgi-extra {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 70px;
  }

  .single-blog-page .blog-thumb .rgi-extra .rgi-star,
  .single-blog-page .blog-thumb .rgi-extra .rgi-heart {
    height: 35px;
    width: 35px;
    float: left;
    display: block;
    padding: 6px 8px 0;
  }

  .single-blog-page .blog-thumb .rgi-extra .rgi-star {
    background: #68cd86;
  }

  .single-blog-page .blog-thumb .rgi-extra .rgi-heart {
    background: #ff205f;
  }

  .single-blog-page .blog-content h3 {
    margin-bottom: 10px;
  }

  .single-blog-page .blog-content .meta-comment {
    display: block;
    font-size: 12px;
    color: #737373;
    margin-bottom: 25px;
  }

  .single-blog-page .blog-content p {
    margin-bottom: 25px;
  }

  .comment-warp {
    padding-top: 50px;
  }

  .comment-title {
    margin-bottom: 45px;
  }

  .comment-list {
    list-style: none;
  }

  .comment-list li {
    margin-bottom: 40px;
    overflow: hidden;
  }

  .comment-list .comment .comment-avator {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    float: left;
  }

  .comment-list .comment .comment-content {
    padding-left: 100px;
  }

  .comment-list .comment .comment-content h5 {
    font-size: 12px;
    color: #212b34;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .comment-list .comment .comment-content h5 span {
    color: #68cd86;
    padding-left: 18px;
  }

  .comment-list .comment .comment-content p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .comment-list .comment .comment-content .reply {
    font-size: 12px;
    color: #ff1d55;
  }

  .comment-form-warp {
    padding-top: 30px;
  }

  .comment-form input[type='text'],
  .comment-form input[type='email'],
  .comment-form textarea {
    width: 100%;
    height: 41px;
    border: 1px solid #d6dee7;
    padding-left: 22px;
    padding-right: 45px;
    font-size: 12px;
    margin-bottom: 24px;
  }

  .comment-form textarea {
    height: 243px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 38px;
  }

  /*-----------------
    Review page
  --------------------*/

  .review-page .review-item {
    margin-bottom: 63px;
  }

  .review-page .review-item .review-text h4 {
    margin-bottom: 10px;
  }

  .review-dark .review-item .review-text p {
    color: #b5b5b5;
  }

  .review-section.review-dark {
    background-position: center top;
  }

  /*-----------------
    Community page
  --------------------*/

  .community-page .site-pagination {
    padding-top: 62px;
  }

  .community-warp {
    max-width: 1367px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.28);
  }

  .community-post-list {
    list-style: none;
  }

  .community-post-list li {
    padding: 33px 55px 65px;
    margin-bottom: 21px;
    overflow: hidden;
    border: 1px solid #878787;
    background: #1f2225;
  }

  .community-post-list .community-post .author-avator {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    float: left;
  }

  .community-post-list .community-post .post-content {
    padding-left: 100px;
  }

  .community-post-list .community-post .post-content h5 {
    font-size: 16px;
    color: #ff205f;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .community-post-list .community-post .post-content h5 span {
    color: #7a7a7a;
    padding-left: 18px;
  }

  .community-post-list .community-post .post-content p {
    color: #fff;
    margin-bottom: 5px;
  }

  .community-post-list .community-post .post-content .post-date {
    font-size: 12px;
    color: #68cd86;
    margin-bottom: 18px;
  }

  .community-post-list .attachment-file {
    padding-top: 60px;
    max-width: 435px;
  }

  .community-top-title {
    margin-bottom: 50px;
  }

  .community-filter {
    margin-bottom: 80px;
  }

  .community-filter label {
    font-size: 18px;
    color: #1f2225;
    font-weight: 500;
    padding-right: 10px;
  }

  .community-filter select {
    width: 201px;
    height: 42px;
    font-size: 14px;
    padding: 0 18px;
    color: #1f2225;
    font-weight: 500;
  }

  /*-----------------
    Contact page
  --------------------*/

  .map {
    height: 515px;
    background: #ddd;
    margin-bottom: 90px;
  }

  .contact-info-list {
    padding-top: 55px;
    padding-bottom: 20px;
    list-style: none;
  }

  .contact-info-list li {
    margin-bottom: 30px;
  }

  .contact-info-list .cf-left {
    width: 95px;
    float: left;
    font-size: 16px;
    font-weight: 700;
    color: #68cd86;
  }

  .contact-info-list .cf-right {
    color: #878787;
    font-size: 15px;
    padding-left: 100px;
  }

  .social-links a {
    color: #474747;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 20px;
  }

  /*------------------
    Responsive
  ---------------------*/

  @media (min-width: 1200px) {
    .container {
      max-width: 1176px;
    }
  }

  /* Medium screen : 992px. */

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu {
      margin-right: 90px;
    }
    .hero-slider .owl-dots {
      max-width: 930px;
    }
    .review-section {
      background-position: right 10% top;
    }
    .review-item .review-cover {
      height: 280px;
    }
    .latest-blog .lb-item .lb-thumb {
      width: 55px;
      height: 55px;
    }
    .latest-blog .lb-item .lb-content {
      padding-left: 67px;
    }
    .top-comment .tc-item .tc-content {
      padding-left: 95px;
    }
    .footer-top-bg {
      max-width: 370px;
    }
    .page-info-section .pi-content h2 {
      font-size: 50px;
    }
  }

  /* Tablet :768px. */

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .site-logo {
      max-width: 130px;
    }
    .main-menu {
      margin-right: 25px;
    }
    .main-menu ul li a {
      margin-left: 15px;
    }
    .user-panel {
      padding: 8px 15px;
    }
    .hero-slider .hs-text h2 {
      font-size: 50px;
    }
    .hero-slider .owl-dots {
      max-width: 690px;
    }
    .recent-game-item {
      margin-bottom: 42px;
    }
    .tournament-item .ti-thumb {
      width: 100%;
      height: 230px;
      float: none;
    }
    .tournament-item .ti-text {
      padding-left: 0;
      padding-top: 20px;
    }
    .review-section {
      background-position: left top;
    }
    .review-item {
      margin-bottom: 63px;
    }
    .footer-logo {
      margin-bottom: 50px;
    }
    .latest-blog .lb-item .lb-thumb {
      width: 80px;
      height: 80px;
    }
    .latest-blog .lb-item .lb-content {
      padding-left: 95px;
    }
    .footer-top-bg {
      display: none;
    }
  }

  /* Large Mobile :480px. */

  @media only screen and (max-width: 767px) {
    .header-section {
      overflow: visible;
      padding: 23px 0;
    }
    .header-section .container {
      position: relative;
    }
    .site-logo {
      float: none;
      padding-top: 0;
    }
    .main-menu {
      display: none;
      position: absolute;
      top: calc(100% + 24px);
      left: 0;
      width: 100%;
      background: #ffffff;
      z-index: 999;
    }
    .main-menu ul li {
      display: block;
      border-bottom: 1px solid #ececec;
    }
    .main-menu ul li a {
      margin-left: 0;
      color: #212b34;
      padding: 13px 26px;
    }
    .nav-switch {
      color: #fff;
      display: block;
      float: right;
      cursor: pointer;
      font-size: 25px;
    }
    .user-panel {
      display: none;
    }
    .hero-slider .hs-item {
      height: 700px;
    }
    .hero-slider .hs-text h2 {
      font-size: 36px;
    }
    .hero-slider .owl-dots {
      max-width: 510px;
    }
    .ln-title {
      padding-right: 30px;
    }
    .feature-item {
      margin-bottom: 30px;
    }
    .recent-game-item {
      margin-bottom: 42px;
    }
    .review-section {
      background-position: left top;
    }
    .review-item {
      margin-bottom: 63px;
    }
    .footer-logo {
      margin-bottom: 50px;
    }
    .footer-top-bg {
      display: none;
    }
    .footer-section {
      padding: 30px 0 90px;
      text-align: center;
    }
    .footer-menu li:first-child a {
      margin-left: 0;
    }
    .footer-menu,
    .copyright {
      float: none;
    }
    .copyright {
      padding-top: 15px;
    }
    .community-post-list li {
      padding: 30px 25px 30px;
    }
    .page-info-section .pi-content h2 {
      font-size: 50px;
    }
  }

  /* Medium Mobile :480px. */

  @media only screen and (max-width: 576px) {
    .hero-slider .owl-dots {
      padding: 0 15px;
    }
    .feature-section {
      padding-left: 15px;
      padding-right: 15px;
    }
    .community-post-list .community-post .author-avator {
      float: none;
    }
    .community-post-list .community-post .post-content {
      padding-left: 0;
      padding-top: 30px;
    }
  }

  /* Small Mobile :320px. */

  @media only screen and (max-width: 479px) {
    .hero-slider .hs-item {
      height: auto;
      padding: 150px 0;
    }
    .ln-title {
      display: none;
    }
    .news-ticker {
      width: 100%;
    }
    .tournament-item .ti-thumb {
      width: 100%;
      height: 230px;
      float: none;
    }
    .tournament-item .ti-text {
      padding-left: 0;
      padding-top: 20px;
    }
    .latest-blog .lb-item .lb-thumb {
      width: 55px;
      height: 55px;
    }
    .latest-blog .lb-item .lb-content {
      padding-left: 67px;
    }
    .top-comment .tc-item .tc-content {
      padding-left: 85px;
    }
    .site-btn {
      padding: 11px 30px;
      min-width: 145px;
    }
    .comment-list .comment .comment-avator {
      float: none;
    }
    .comment-list .comment .comment-content {
      padding-left: 0;
      padding-top: 20px;
    }
    .footer-widget {
      padding: 40px 15px 7px;
    }
    .page-info-section .pi-content h2 {
      font-size: 40px;
    }
  }

}