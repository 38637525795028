
.store{

  .col-left-border::before{
    background-color: rgb(232,234,237);
    content: "";
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: calc(50% - 12px);
    width: 1px;
  }
  .col-left-border{
    position: relative;
  }
  
  .hide-scroll-bars{
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }
  
  .hide-scroll-bars::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
  
  .store-loader {
    position: absolute;
    margin: 0 auto;
    z-index: 100;
    left: 5px;
    width: 87px;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke: $green;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  .btn-primary-store{
    font-weight: 500;
    font-size: 14px;
    color: white;
    background-color: #01875f;
    border-radius: 4px;
    padding: 8px;
    --bs-btn-active-bg: #095943;
    --bs-btn-active-color: white;
  }

  .btn-secondary-store{
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    background-color: white;
    color: #01875f;
    border-color: #afafaf;
    border-style: solid !important;
    border-width: 1px;
    box-sizing: border-box;
    padding: 7px;
  }

  .logo-img-loading{
    margin: 24px 24px 19px 18px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 12px;
    width: 36px;
    height: 36px;
  }

  .logo-img{
    margin: 7px 24px 0 0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 16px;
    width: 72px;
    height: 72px;
  }
}//store