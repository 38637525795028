@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'fonts';
@import 'app';
@import 'animation';
@import 'terminal';
@import 'landing';
@import 'install.scss';



body {
  overflow: hidden;
  margin: 0;
  font-family: var(--theme-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  user-select: none;
  background-color: var(--theme-background);  
}

body>textarea {
  resize: vertical;
  position: absolute;
  z-index: -100;
  visibility: hidden;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
}
