$max_phones--big: 'max-width: 767px';
$min_phones--big: 'min-width: 768px';

.app {
  touch-action: none;
}


:root{
  --theme-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --theme-default: #fbfbfb;
  --theme-default-accent: white;
  --theme-primary-dark: #5bb576;//#4f9a65;
  --theme-primary: #68cd86; 
  --theme-primary-accent: #95ffb5; //#77e097
  --theme-primary-transparent: #95ffb524; //#77e097
  --theme-overlay: #07080a;
  --theme-overlay-secondary: #212b34;//#2d2f3a;
  --theme-overlay-accent: #32343d;//#22232a;
  --theme-overlay-transparent: #07080a66;
  --theme-overlay-accent-transparent: #07080aad;

  --theme-overlay-glass: #ffffff08;

  --theme-muted: #80878c;//#69737a
  --theme-muted-dark: #62676a;//#69737a
  --theme-muted-transparent: #80878c4d;//#69737a4d;
  --theme-background-light: #28343e;
  --theme-background: #212b34;
  --theme-background-dark: #1f2931;
  --theme-background-accent: #42576a;
  --theme-red-light: #ff6565;
  --theme-red: #e83f0c;
  --theme-red-transparent: #c7090924;
  --theme-red-dark: #C43D16;//#882626;
  --theme-red-dark-transparent: #FF1616ac;
  --theme-yellow: #FFF177;


  --theme-emerald-light: #a7cdac;
  --theme-emerald: #72B37B;
  --theme-emerald-dark: #4f9a65;

  --theme-bronze-light: #e3c98c;
  --theme-bronze: #E9B271;//#FFEBD0;
  --theme-bronze-dark: #c0651a;

  --theme-silver-light: #EAF0FF;
  --theme-silver: #cacfdd;
  --theme-silver-dark: #757575;

  --theme-gold-light: #ffff99;
  --theme-gold: #f6d568; 
  --theme-gold-dark: #ebba17;

  --theme-crystal-light: #ECF6FF;
  --theme-crystal: #8cb6da;
  --theme-crystal-dark: #4495B2;


  // --theme-ruby-light: #ffd6d6;
  // --theme-ruby: #bf3b3b;
  // --theme-ruby-dark: #6f2424;
  // --theme-ruby-light: #ffd4d4;
  // --theme-ruby: #fc9797;
  // --theme-ruby-dark: #d94f4f;

  --theme-ruby-light: #d4d4ff;
  --theme-ruby: #9797fc;
  --theme-ruby-dark: #4f5fd9;


  --theme-ruby-pink-light: #ffd4f7;
  --theme-ruby-pink: #fc97da;
  --theme-ruby-pink-dark: #d94fb7;

  --theme-ruby-red-light: #ff9f9f;
  --theme-ruby-red: #fc9797;
  --theme-ruby-red-dark: #e01313;


  --theme-amethyst-light: #fbf5ff;
  --theme-amethyst: #e2abff;
  --theme-amethyst-dark: #461761;

  --theme-amethyst-velvet-light: #fde1ff;
  --theme-amethyst-velvet: #e973e5;
  --theme-amethyst-velvet-dark: #9a29bc;

  --theme-obsidian-light: #5C6F81;
  --theme-obsidian: #48535D;
  --theme-obsidian-dark: #363A3D;

    

  --bs-border-width: 0;
  --bs-border-radius: 0;
  --bs-body-color: ver(--theme-overlay); 
  --bs-body-bg: var(--theme-default) !important;
  --bs-form-check-bg: var(--theme-default) !important;

  --toastify-color-success: var(--theme-primary) !important;
  --toastify-color-error: var(--theme-red) !important;
  --toastify-font-family: var(--theme-font-family) !important;
  --toastify-color-dark: var(--theme-overlay-secondary) !important;
  --toastify-toast-min-height: 40px !important;
  --toastify-color-light: var(--theme-default) !important;
  --toastify-z-index: 99 !important;

  --bs-link-color-rgb: var(--theme-primary) !important;
  --bs-link-hover-color: var(--theme-primary) !important;
  --bs-link-hover-color-rgb: var(--theme-primary-accent) !important;
  --bs-link-active-color-rgb: var(--theme-primary) !important;
}
 
.toastify-congrats-message{
  top: 55px;
  opacity: 0.95 !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="checkbox"]:focus,
.uneditable-input:focus {   
  border-color: rgba(126, 239, 104, 0.8);
  //box-shadow: none;//0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}


.form-check-input{
  cursor: pointer;
  height: 30px !important;
  width: 50px !important;
  border-radius: 15px;

  background-color: var(--theme-default);
  border-color: var(--theme-default);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.2)'/></svg>") !important;

 &:checked {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>") !important;
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);  
  }
}

.Toastify__toast-icon{
  width: 32px !important;
}

.Toastify__toast{
  border-radius: 0 !important;
  @media (#{$max_phones--big}) {
    width: 90% !important;
    border-radius: 20px !important;
    margin: 10px auto !important;    
    box-shadow: 0px 5px 15px var(--theme-overlay-transparent) !important;  
  }
}

.Toastify__progress-bar{
  height: 1px !important;
}

.Toastify__toast-body{
  font-size: var(--bs-body-font-size);
  font-weight: 300;
  //font-family: var(--theme-font-family);  
}

.text-default{
  color: var(--theme-default) !important;
}

.text-default-accent{
  color: var(--theme-default-accent) !important;
  font-weight: 400;
}

.text-muted{
  color: var(--theme-muted) !important;
}

.text-primary{
  color: var(--theme-primary) !important;
}

.text-primary-accent{
  color: var(--theme-primary-accent) !important;
}

.text-gold{
  color: var(--theme-gold-dark) !important;
}

.text-red{
  color: var(--theme-red) !important;
  font-weight: 400;
}

.text-bold{
  font-weight: 600;
}


.text-monospace{
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.6px;
}

h1, .h1{
  //text-transform: capitalize;
  line-height: 2.6rem;
  font-size: 2.4rem;
  font-weight: 300;
  margin: 1vh 0px 1vh 0px;
  color: var(--theme-default-accent);
}


h2, .h2{
  line-height: 1.8rem;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1vh 0px 1vh -2px;
  color: var(--theme-default-accent);
}

input{
  line-height: 48px;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 14px;
  margin: 10px 0 0;

  &:disabled{
    background-color: var(--theme-overlay-transparent);
    color: var(--theme-default);
  }  
}



.invalid-feedback{
  display: block;
  color: var(--theme-red);
  font-weight: 500;
  font-size: 0.765rem;
}

.warning-feedback{
  display: block;
  color: var(--theme-yellow);
  font-weight: 500;
  font-size: 0.765rem;
}

small{
  font-weight: 500;
  font-size: 0.765rem;
  letter-spacing: -0.5px;
}

img{
  pointer-events: none;
}

.btn{
  opacity: 1;
  --bs-btn-border-width: 1px;
  transition: none !important;
  //padding: 6px 50px;
  font-weight: 500;
  border-radius: 6px;
  white-space:nowrap;

  //@media (#{$max_phones--big}) {
    width: 100%;
  //}
}

.btn-icon{
  position: relative;
  display: inline-block;
  line-height: inherit;
  img{
    position: absolute;
    width: 20px;
    transform: translate(-130%, -14px);
  }
}

.btn-primary{
  line-height: 35px;
  --bs-btn-color: var(--theme-primary);
  --bs-btn-hover-color: var(--theme-primary-accent);
  --bs-btn-active-color: var(--theme-primary-accent);
  --bs-btn-disabled-color: var(--theme-muted);

  --bs-btn-bg: var(--theme-overlay);
  --bs-btn-hover-bg: var(--theme-overlay-accent);
  --bs-btn-active-bg: var(--theme-overlay-accent);
  --bs-btn-disabled-bg: var(--theme-overlay);
  line-height: 48px;
  --bs-btn-hover-bg: var(--theme-overlay);

  --bs-btn-focus-shadow-rgb: none;//49, 132, 253;
  --bs-btn-active-shadow: none;//inset 0 3px 5px rgba(0, 0, 0, 0.125);

  --bs-btn-border-color: var(--theme-overlay);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-border-color: var(--theme-overlay);

}

.btn-secondary{
  transform: translate3d(0, 0, 1);
  backdrop-filter: blur(10px);        

  line-height: 35px;
  --bs-btn-color: var(--theme-default);
  --bs-btn-hover-color: var(--theme-default-accent);
  --bs-btn-active-color: var(--theme-default-accent);
  --bs-btn-disabled-color: var(--theme-muted);

  --bs-btn-bg: transparent;
  --bs-btn-hover-bg: var(--theme-overlay-accent);
  --bs-btn-active-bg: var(--theme-overlay-accent);
  --bs-btn-disabled-bg: transparent;
  line-height: 48px;
  --bs-btn-hover-bg: transparent;
  

  --bs-btn-focus-shadow-rgb: none;//49, 132, 253;
  --bs-btn-active-shadow: none;//inset 0 3px 5px rgba(0, 0, 0, 0.125);

  --bs-btn-border-color: #ffffff1c;
  --bs-btn-hover-border-color: #ffffff1c;
  --bs-btn-active-border-color: #ffffff1c;
  --bs-btn-disabled-border-color: #ffffff1c;
}

@keyframes flashing-animation {
  from {color: var(--theme-primary-accent); }
  to {color: var(--theme-primary-dark);}
}

.btn-flashing{
  animation: flashing-animation 0.5s steps(2) 0s infinite alternate;
  &:hover{
    animation: none !important;
  }
}


.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--theme-primary-dark);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--theme-primary);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--theme-primary);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--theme-muted);
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  --bs-btn-focus-shadow-rgb: rgba(0, 0, 0, 0);
  text-decoration: underline;
  text-decoration-thickness: from-font;
  line-height: 48px;
  @media (#{$max_phones--big}) {
    --bs-btn-hover-color: var(--theme-primary-dark);    
  }
}

.mobile-only{
  //@media (#{$min_phones--big}) {
  //  display: none;
 // }
}

.desktop-only{
  //@media (#{$max_phones--big}) {
    display: none;
  //}
}

.modal{
  --bs-modal-width: 100%;
  --bs-modal-header-padding: 0 1rem 0 1rem;
  --bs-modal-bg: transparent;//var(--theme-background);  
  --bs-modal-margin: 0;  
  background-color: var(--theme-background); 
  display: block;

  height: 100vh;
  min-height: 100vh;
  @supports (height: 100dvh){
    height: 100dvh;
    min-height: 100dvh;
  }  
  position: fixed;
  overflow: hidden;
  transition: none !important;

/*   @supports (height: 100svh){
    height: 100svh;
    min-height: 100svh;
  }
 */ 
/*  @supports (height: 100lvh){
    height: 100lvh;
  }
 */
  .modal-dialog{
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition: none !important;

    top: 0;
    transform: none;
    height: 83vh;
    min-height: 83vh;

/*     @supports (height: 83svh){
      height: 83svh;
      min-height: 83svh;
    }
 */
    .modal-mobile-navigation{
      line-height: 48px;
      margin: 4px 6px 0;
    }
   
    .modal-content{
      top: 0%;
      transition: none !important;
      
      .modal-header{
        display: block;
        .subtitle {
          @media (#{$max_phones--big}) {
          //  margin-top: -0.8rem;
          }
        }
      }
      .modal-body{
        max-height: 70vh;
        height: 70vh;
        @supports (max-height: 70dvh){
          max-height: 70dvh;
          height: 70dvh;
        }
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
      }

      .no-scroll{
        overflow: hidden;
        max-height: none;
        height: 93vh;
        @supports (height: 93dvh){
          height: 93dvh;
        }
      }      

      .modal-footer{        
        padding-bottom: 0;
        padding-top: 0;
        position: fixed;
        top: 44vh;
        top: 44dvh;
        @supports (top: 44dvh){
          top: 44dvh;
        }
        bottom: auto;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        margin: 0px;
        justify-content: center;
        //backdrop-filter: blur(3px);        
      }    
      .no-mobile-keyboard{        
          top: auto;
          bottom: 3vh;
          @supports (bottom: 3dvh){
            bottom: 3dvh;
          }
      }
    }
  }
}

.visible {
  opacity: 1;
  transition: visibility, opacity 0.5s linear;    
  visibility: visible;
}

.hidden {
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;    
  visibility: hidden;
}


.back-symbol:after{
  transform: scale(2.5, 2.5) translate(-35%, -3%);
  display: inline-block;
  font-weight: 100;
  content: "‹";
}

.forward-symbol{
  margin-top: -8px;
  font-weight: 100;
  &:after{
    transform: scale(2.5, 2.5) translate(-35%, -2%);
    display: inline-block;
    content: "›";
  }
}

.tag{
  margin: 1px 24px 0 0;
  padding: 0px 14px 1px;
  background-color: var(--theme-primary);
  color: var(--theme-default) !important;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
}

.tag-muted{
  margin: 1px 24px 0 0;
  padding: 0px 14px 1px;
  background-color: var(--theme-default);
  color: var(--theme-overlay) !important;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
}

.tag-black{
  margin: 1px 24px 0 0;
  padding: 0px 14px 1px;
  background-color: var(--theme-overlay-accent);
  color: var(--theme-default) !important;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
}

.tag-value{
  margin: 4px 0 0 0;
  padding: 0px 14px 1px;
  color: var(--theme-muted) !important;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}


.list-group-flush{
  border-radius: 10px;
  --bs-list-group-item-padding-y: 20px;
  --bs-list-group-bg: var(--theme-overlay-transparent);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-color: var(--theme-background);

  --bs-list-group-action-hover-color: var(--theme-default-accent);
  --bs-list-group-action-active-color: var(--theme-default-accent);

  --bs-list-group-action-hover-bg: var(--theme-overlay-accent);
  --bs-list-group-action-active-bg: var(--theme-overlay-accent);

  --bs-list-group-active-bg: var(--theme-background);//var(--theme-overlay-accent);
  --bs-list-group-active-border-color: var(--theme-overlay-transparent);//var(--theme-background);


  @media (#{$max_phones--big}) {
    --bs-list-group-action-hover-bg: var(--theme-overlay-transparent);
  }
  .active{
    box-shadow: inset var(--theme-background-accent) 0px 0px 80px 0px; //inset var(--theme-overlay-transparent) 0px 0px 4px 0px
  }
}


.qr-code {
    margin: 0 50%;
    transform: translateX(-50%);
    width: 60%;
    padding: 15px;
    background-color: white;
    border-radius: 15px;
}


.connection-loader{
  position: fixed;
  z-index: 99999;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  @supports (height: 100dvh){
    height: 100dvh;
    min-height: 100dvh;
  }
}


.loader{
    position: fixed;
    z-index: 99999;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    @supports (height: 100dvh){
      height: 100dvh;
      min-height: 100dvh;
    }
}
.overlay{
  background-color: var(--theme-background);
}
.loader:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(var(--theme-primary-accent) 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l5 {
 0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
 33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
 66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
 100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}


.app{
  top: var(--inset-top);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  position: fixed;

  --vh: calc(100vh - var(--inset-top) - var(--inset-bottom));
  @supports (height: 100dvh){
    --vh: calc(100dvh - var(--inset-top) - var(--inset-bottom));
  }
  height: var(--vh);
  min-height: var(--vh);

  width: 100%;
  max-width: 560px;
  background-color: var(--theme-background);
  color: var(--theme-default);
  font-weight: 300;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  /* @supports(height: 100svh){
    height: 100svh;
    min-height: 100lvh;
  } */
}
