@font-face {
  font-family: 'PixelSans';
  src: url('../../public/fonts/Pixel Sans Serif.woff2') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'PixelSans';
  src: url('../../public/fonts/Pixel Sans Serif.woff2') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'PixelSans';
  src: url('../../public/fonts/Pixel Sans Serif.woff2') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../public/fonts/ProductSans-Regular.woff2') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../public/fonts/ProductSans-Medium.woff2') format('truetype');
  font-style: normal;
  font-weight: 500;
}


$font-family-google: 'Google Sans';
$font-family-pixel: 'PixelSans';